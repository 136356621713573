import React from "react";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
const Footer = () => {
    return (
        <div className="w-full  bg-black">
            <div className="block xmin-screen:flex justify-between px-4 items-center">
                <div className="text-center items-center  flex flex-row gap-3 py-1 px-auto">
                    <p className="text-yellow-500 border border-yellow-500 w-fit rounded-tl-2xl rounded-br-2xl xmin-screen:px-4 xmin-screen:py-2 px-1 py-1 ease-in-out duration-300 ">Residence</p>
                    <p className="text-white text-3xl xmin-screen:text-5xl font-luxury ease-in-out duration-300">Seraphine</p>
                </div>
                <div className="flex mt-2 xmin-screen:mt-0 justify-between text-white w-1/2 xmin-screen:m-0 mx-auto xmin-screen:w-[20%] items-center">
                    <p>Home</p>
                    <p>Payment</p>
                    <p>Login</p>
                </div>
            </div>
            <br />
            <hr />
            <div className="text-white py-2">
                <h1 className="text-center text-xl">Follow us on Social Media</h1>
                <div className=" my-2 flex space-x-3 justify-center items-center m-auto">
                    <CiFacebook size={30} />
                    <FaInstagram size={30} />
                    <FaTwitter size={30} />
                    <FaWhatsapp size={30} />
                </div>
                <div className="flex justify-center my-8">
                    <IoCallOutline size={30} />
                    <p>Call Us Now : +212 0696 7996 43</p>
                </div>

            </div>
        </div>
    )
}

export default Footer