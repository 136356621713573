import React from "react";
import HomePage from "./Components/HomePage";
import Fiche from "./Components/Fiche"
// import Footer from "./Components/Footer"
import Login from "./Components/Login"
import HomePageAdmin from "./Admin/HomePageAdmin";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/Fiche" element={<Fiche />} />
          <Route path="/HomePageAdmin" element={<HomePageAdmin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
