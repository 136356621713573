import React from "react";
import Residence from "../Pictures/Residence.jpg"
import Residence2 from "../Pictures/Residence1.jpg"
import ResidenceLocation from "../Pictures/ResidenceLocation.jpg"
import ResidencePlan from "../Pictures/Residence2.jpg"
import Footer from "./Footer";
import { Link, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
const HomePage = () => {
    return (
        <div className="w-full">
            <Navbar />
            <div className="w-full flex justify-between h-[450px]">
                <img src={Residence} alt="" className="w-[50%] min-screen:block hidden " />
                <div className="w-full min-screen:w-[45%] space-y-9">
                    <h1 className="text-8xl text-center mt-[20%]">Welcome To</h1>
                    <div className="flex items-center mx-auto justify-center min-screen:flex-row w-full gap-x-3 flex-col ">
                        <p className="text-black text-7xl w-fit font-luxury">Seraphine</p>
                        <p className="text-yellow-500 border text-2xl border-yellow-500 h-[100%] rounded-tl-2xl rounded-br-2xl px-4 py-2">Residence</p>
                    </div>
                </div>
            </div>

            <div className="bg-black w-full block xmin-screen:flex items text-white pt-10 min-screen:pt-0 ">
                <p className=" text-center w-full xmin-screen:w-[50%] m-auto ">
                    Seraphine is an exquisite 18th century manor nestled on over 30 acres of picturesque French countryside.
                    This luxurious estate features meticulously landscaped grounds, a sparkling swimming pool, tennis court,
                    and miles of private walking trails. The beautifully restored main house offers guests spacious en-suite
                    bedrooms, a grand dining room, gourmet kitchen, and an extensive wine cellar. Blending historic charm with
                    modern amenities, Seraphine provides an unmatched experience of refined elegance and tranquility.
                </p>
                <div className="w-full xmin-screen:w-[50%]">
                    <img src={Residence2} alt="" className="min-screen:w-full mt-10 min-screen:mt-0 w-[90%] mx-auto" />
                </div>
            </div>

            <div className="w-full bg-black text-white">
                <h1 className="text-xl xmin-screen:text-4xl text-yellow-500 px-5 py-7">PROJECT LOCATION</h1>
                <img src={ResidenceLocation} alt="" className="w-[60%] mx-auto" />
            </div>

            <div className="text-center py-[5%]">
                <h1 className="text-xl ">TAKE A LOOK</h1>
                <h1 className="text-3xl">FLOOR PLANS</h1>
                <img src={ResidencePlan} alt="" className="w-[60%] mx-auto" />
            </div>
        <Footer /> 

        </div>
    );
}

export default HomePage