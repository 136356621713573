import axios from "axios";
import React, { useState } from "react";

const AjoutArchive = ({ handleClose }) => {
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        cnie: "",
        nom: "",
        prenom: "",
        tele: "",
        motPasse: "",
        periode: "mensuelle",
        NoLot: "",
        typeLot: "appartement",
        dateAqui: "",
        etatLot: "proprietaire",
        prixMois: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        axios.post("http://localhost/residence/admin/ajoutArchive.php", formData)
        .then((response) => {
            console.log(response); // Log the full response
            setLoading(false);
            if (response.data.status === "success") {
                setMessage("Habitant ajouté avec succès !");
                setFormData({
                    cnie: "",
                    nom: "",
                    prenom: "",
                    tele: "",
                    motPasse: "",
                    periode: "mensuelle",
                    NoLot: "",
                    typeLot: "appartement",
                    dateAqui: "",
                    etatLot: "",
                    prixMois: ""
                });
            } else {
                setMessage(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error); // Log any errors
            setLoading(false);
            setMessage("Une erreur est survenue lors de l'ajout de l'habitant.");
        });
    };

    return (
        <div className="min-h-screen bg-white bg-opacity-50 flex justify-center items-center py-8">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-4xl mx-4 flex flex-col space-y-4">
                {/* <h2 className="text-2xl font-bold text-center ">Ajouter un enregistrement</h2> */}
                {message && <p className={`text-center ${message.includes('succès') ? 'text-green-500' : 'text-red-500'}`}>{message}</p>}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Lot Details */}
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Détails du Lot</h3>
                        <div>
                            <label htmlFor="NoLot" className="block text-gray-700 text-sm font-bold mb-1">N° LOT:</label>
                            <input
                                type="text"
                                name="NoLot"
                                id="NoLot"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.NoLot}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="typeLot" className="block text-gray-700 text-sm font-bold mb-1">Type de Lot:</label>
                            <select
                                name="typeLot"
                                id="typeLot"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.typeLot}
                                required
                            >
                                <option value="appartement">Appartement</option>
                                <option value="villa">Villa</option>
                                <option value="magasin">Magasin</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="dateAqui" className="block text-gray-700 text-sm font-bold mb-1">Date d'Acquisition:</label>
                            <input
                                type="date"
                                name="dateAqui"
                                id="dateAqui"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.dateAqui}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="etatLot" className="block text-gray-700 text-sm font-bold mb-1">Occupé par:</label>
                            <select
                                name="etatLot"
                                id="etatLot"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.etatLot}
                                required
                            >
                                <option value="0">Locataire</option>
                                <option value="1">Propriétaire</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="prixMois" className="block text-gray-700 text-sm font-bold mb-1">Tarif (par mois):</label>
                            <input
                                type="text"
                                name="prixMois"
                                id="prixMois"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.prixMois}
                                required
                            />
                        </div>
                    </div>

                    {/* Personal Information */}
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Informations Personnelles</h3>
                        <div>
                            <label htmlFor="cnie" className="block text-gray-700 text-sm font-bold mb-1">CNIE:</label>
                            <input
                                type="text"
                                name="cnie"
                                id="cnie"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.cnie}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="nom" className="block text-gray-700 text-sm font-bold mb-1">Nom:</label>
                            <input
                                type="text"
                                name="nom"
                                id="nom"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.nom}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="prenom" className="block text-gray-700 text-sm font-bold mb-1">Prénom:</label>
                            <input
                                type="text"
                                name="prenom"
                                id="prenom"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.prenom}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="tele" className="block text-gray-700 text-sm font-bold mb-1">Téléphone:</label>
                            <input
                                type="text"
                                name="tele"
                                id="tele"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.tele}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="motPasse" className="block text-gray-700 text-sm font-bold mb-1">Mot de passe:</label>
                            <input
                                type="password"
                                name="motPasse"
                                id="motPasse"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.motPasse}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="periode" className="block text-gray-700 text-sm font-bold mb-1">Période:</label>
                            <select
                                name="periode"
                                id="periode"
                                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
                                onChange={handleChange}
                                value={formData.periode}
                                required
                            >
                                <option value='mensuelle'>mensuelle</option>
                                <option value='trimestrielle'>trimestrielle</option>
                                <option value='annuelle'>annuelle</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                        disabled={loading}
                    >
                        {loading ? "Ajout en cours..." : "Ajouter"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AjoutArchive;
