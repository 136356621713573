import axios from "axios";
import React, { useEffect, useState } from "react";

const Lotissement = ({ NoLot, cnie }) => {
    const [annee, setAnnee] = useState(2024);
    const [Lot, setLot] = useState({
        typeLot: "",
        dateAqui: "",
        etatLot: "",
        prixMois: null,
        moisNonPayes: [],
        moisPayes: []
    });

    useEffect(() => {
        axios.get(`http://localhost/residence/lotissement.php?cnie=${cnie}&NoLot=${NoLot}&annee=${annee}`)
            .then((response) => {
                setLot(response.data);
            })
            .catch((error) => {
                console.error("There was an error fetching the data!", error);
            });
    }, [NoLot, cnie, annee]);

    const handleAnnee = (e) => {
        setAnnee(parseInt(e.target.value, 10));
    };

    return (
        <>
            <fieldset className="border-2 border-gray-300 rounded p-4 mb-4">
                <legend className="px-2 font-bold text-gray-700">LOT : {NoLot}</legend>
                <label htmlFor="annee">Année : </label>
                <select name="annee" className="bg-gray-300 px-2" id="annee" onChange={handleAnnee}>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                </select>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="number">Numéro :</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="number"
                        type="text"
                        name="number"
                        value={NoLot}
                        readOnly
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="type">Type :</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="type"
                        type="text"
                        name="type"
                        readOnly
                        value={Lot.typeLot}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="occupant">Occupé par :</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="occupant"
                        type="text"
                        name="occupant"
                        readOnly
                        value={Lot.etatLot === '1' ? 'Propriétaire' : 'Locataire'}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="dateAqui">Date Acquisition :</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="dateAqui"
                        type="text"
                        name="dateAqui"
                        readOnly
                        value={Lot.dateAqui}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="prixMois">Tarif du mois :</label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="prixMois"
                        type="text"
                        name="prixMois"
                        readOnly
                        value={Lot.prixMois ? `${Lot.prixMois} MAD` : ""}
                    />
                </div>
                <table className="border-collapse border border-gray-400 mx-auto mt-8">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-400 p-2">Mois Payés :</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                {Lot.moisPayes.length > 0 ? (
                                    Lot.moisPayes.map((mois, index) => (
                                        <span key={index}>{mois} | </span>
                                    ))
                                ) : (
                                    <span>Aucun mois n'est payé</span>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="border-collapse border border-gray-400 mx-auto mt-8">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-400 p-2">Mois Non Payés :</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">
                                {Lot.moisNonPayes.length > 0 ? (
                                    Lot.moisNonPayes.map((mois, index) => (
                                        <span key={index}>{mois} | </span>
                                    ))
                                ) : (
                                    <span>Tous les mois sont payés !</span>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="mt-8 font-extrabold">
                    Total Non Payé en {annee} : {Lot.moisNonPayes.length * Lot.prixMois} MAD
                </div>
            </fieldset>
        </>
    );
};

export default Lotissement;
