import React, { useEffect, useState } from "react";
import NavbarAdmin from "./NavbarAdmin";
import Archive from "./Archive";
import AjoutArchive from "./AjoutArchive";
import EffectuerPaiement from "./EffectuerPaiement";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HomePageAdmin = () => {
    const [operation, setOperation] = useState(1);
    const navigate = useNavigate();

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null;
    }

    function deleteCookie(name) {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    }

    useEffect(() => {
        const cnieValue = getCookie("cnie");
        if (cnieValue === null) {
            alert("Veuillez vous connecter!");
            navigate("/");
        } else {
            axios.post(`http://localhost/residence/admin/verifierAdmin.php`, { cnie: cnieValue })
                .then((response) => {
                    if (!response.data.isAdmin) {
                        alert("Accès non autorisé! Vous n'êtes pas un administrateur.");
                        deleteCookie("cnie");
                        navigate("/");
                    }
                })
                .catch((error) => {
                    console.error("Erreur de vérification de l'administrateur: ", error);
                    alert("Erreur lors de la vérification des informations d'identification.");
                    deleteCookie("cnie");
                    navigate("/");
                });
        }
    }, [navigate]);

    return (
        <div className="flex flex-col ">
            <div>
                <NavbarAdmin />
                <div className="w-full flex min-screen:flex-row flex-col justify-between bg-gray-900 text-white text-center">
                    <div
                        className={`w-full cursor-pointer ${operation === 1 ? 'bg-gray-500' : ''}`}
                        onClick={() => setOperation(1)}
                    >
                        Archive
                    </div>
                    <div
                        className={`w-full cursor-pointer ${operation === 2 ? 'bg-gray-500' : ''}`}
                        onClick={() => setOperation(2)}
                    >
                        Ajouter Lotissement
                    </div>
                    <div
                        className={`w-full cursor-pointer ${operation === 3 ? 'bg-gray-500' : ''}`}
                        onClick={() => setOperation(3)}
                    >
                        Effectuer un payment
                    </div>
                </div>
            </div>

            <div className="flex flex-row border-t w-full ">
                <div className="w-full">
                    {operation === 1 && <Archive />}
                    {operation === 2 && <AjoutArchive />}
                    {operation === 3 && <EffectuerPaiement />}
                </div>
            </div>
        </div>
    );
};

export default HomePageAdmin;
