import React, {useState} from "react";
import { FaWallet } from "react-icons/fa";
// import { IoPersonCircleSharp } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
    // const [showLogin, setShowLogin]=useState(true)
    const navigate=useNavigate()
    
    function deleteCookie(name) {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    }

    const handleLogOut = () => {
        deleteCookie('cnie');
        // setShowLogin(true);
        navigate("/");
        // window.location.reload();
    }
    
    return (
        <div className="">
            <div className="w-full text-white bg-black flex top-[2%] items-center md:flex-row justify-between px-4" >
                <a href="./Homepage">
                    <div className="text-center items-center flex flex-row gap-3 py-1">
                        <p className="text-yellow-500 border border-yellow-500 w-fit rounded-tl-2xl rounded-br-2xl xmin-screen:px-4 xmin-screen:py-2 px-1 py-1 ease-in-out duration-300 ">Residence</p>
                        <p className="text-white text-3xl xmin-screen:text-5xl font-luxury ease-in-out duration-300">Seraphine</p>
                    </div>
                </a>
                <div className="flex space-x-3 items-center">
                    <Link to="/Fiche"><FaWallet size={25} /></Link>
                    {/* <IoPersonCircleSharp size={30} onClick={setShowLogin} className="cursor-pointer" /> */}
                    <input 
                        type="submit"
                        className=" text-yellow-500 border py-1 px-2 focus:outline-none rounded-lg hover:bg-yellow-500 hover:text-white ease-in-out duration-300  border-yellow-500  cursor-pointer " 
                        value={"Log Out"}
                        onClick = {handleLogOut} 
                        />
                </div>
            </div>
            {/* <div className={showLogin?"block":"hidden"} >
                <Login showLogin={showLogin} setShowLogin={setShowLogin} />  
            </div> */}
            
        </div>
    )
}

export default Navbar;