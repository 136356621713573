import React, { useEffect, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";

const Historique = ({ NoLot, closeHistory }) => {
    const [historicalData, setHistoricalData] = useState([]);
    const [personInfo, setPersonInfo] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHistoriqueData();
    }, []);

    const fetchHistoriqueData = async () => {
        try {
            const response = await axios.get(`http://localhost/residence/admin/historique.php?NoLot=${NoLot}`);
            if (response.data.status === "success") {
                setHistoricalData(response.data.result);
                setPersonInfo(response.data.person);
                setError(null);
            } else {
                setError(response.data.msg);
            }
        } catch (error) {
            setError("An error occurred while fetching historical data.");
            console.error("Fetch historical data error: ", error);
        }
    };

    const generatePDF = (item) => {
        const { mois_paiements, date_paiement, payment_methods } = item;
        const { nom, prenom, tele } = personInfo;
        const doc = new jsPDF();

        // Add the title
        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);
        doc.text("Reçu de Paiement", 20, 20);

        // Add the payment receipt details
        doc.setFontSize(14);
        doc.setFont("times", "normal");
        doc.text(`N° LOT: ${NoLot}`, 20, 40);
        doc.text(`Nom: ${nom}`, 20, 50);
        doc.text(`Prénom: ${prenom}`, 20, 60);
        doc.text(`Téléphone: ${tele}`, 20, 70);

        // Add the list of paid months
        doc.text("Mois Payés:", 20, 80);
        mois_paiements.split(', ').forEach((month, index) => {
            doc.text(month, 20, 90 + (index * 10));
        });

        // Add payment methods
        doc.text("Mode de Paiement:", 20, 100 + (mois_paiements.split(', ').length * 10));
        const splitMethods = payment_methods.split(', ');
        for (let i = 0; i < splitMethods.length; i++) {
            doc.text(splitMethods[i], 40, 110 + (mois_paiements.split(', ').length * 10) + (i * 10));
        }

        // Format the payment date
        let formattedDate = new Date(date_paiement).toLocaleDateString('fr-FR');

        doc.text(`Date de Paiement: ${formattedDate}`, 20, 120 + (mois_paiements.split(', ').length * 10) + (splitMethods.length * 10));

        // Add signature placeholders
        doc.text("Signature du Syndic", 20, 140 + (mois_paiements.split(', ').length * 10) + (splitMethods.length * 10));
        doc.text("Signature du Résident", 120, 140 + (mois_paiements.split(', ').length * 10) + (splitMethods.length * 10));

        doc.save(`Reçu_Paiement_${NoLot}_${formattedDate}.pdf`);
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-[850px] overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">Historique des paiements pour le N° LOT: {NoLot}</h2>
                {error && <div className="text-red-500 text-center mb-4">{error}</div>}
                <div className="overflow-auto">
                    <table className="w-full divide-gray-200">
                        <thead className="bg-gray-200">
                            <tr>
                                <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date de paiement
                                </th>
                                <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Mois Payés
                                </th>
                                <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Mode de Paiement
                                </th>
                                <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Lien PDF
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {historicalData.map((item) => (
                                <tr key={item.date_paiement}>
                                    <td className="px-3 py-4 whitespace-nowrap">{item.date_paiement}</td>
                                    <td className="px-3 py-4 whitespace-nowrap">{item.mois_paiements}</td>
                                    <td className="px-3 py-4 whitespace-nowrap">{item.payment_methods}</td>
                                    <td className="px-3 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => generatePDF(item)}
                                            className="text-blue-600 hover:text-blue-900"
                                        >
                                            Télécharger le reçu
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-4 flex justify-end">
                    <button onClick={closeHistory} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Fermer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Historique;
