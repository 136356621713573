import React, {useState,useEffect} from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
// import Residence2 from "../Pictures/Residence1.jpg"
import Footer from "./Footer";
import Lotissement from "./Lotissement";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";


    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null;
    }

const Fiche = () => {

    const goBack = () => {
        window.history.back();
    }
    
    const navigate = useNavigate();
    const [cnie, setCnie] = useState("");

    //avoir les id lotissements de l 'utilisateur
    // const[NoLots, setNoLotS]=useState()
    const [user,setUser]=useState({
        cnie: "",
        nom : "",
        prenom:"",
        tel : null,
        periode:"",
        ids:[]
    })
    useEffect(() => {
        const cnieValue = getCookie("cnie");
        if (cnieValue === null) {
            alert("Veuillez vous connecter!");
            navigate("/");
        } else {
            setCnie(cnieValue);
            // alert(cnieValue)
            user.cnie=cnieValue
            axios.post(`http://localhost/residence/fiche.php`,user).then(
                (response)=>{
                    // alert(response.data)
                    setUser(response.data)
                    // alert(response.data.ids)
                }
            )
            // alert(cnieValue);
        }
    }, [navigate]);
    return (
        <div className="" >
            <Navbar />
            <div className="mt-[2%] cursor-pointer" onClick={goBack}><IoMdArrowRoundBack size={30}/></div>
            <div className="my-8 mx-auto max-w-screen-min-screen">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <fieldset className="border-2 border-gray-300 rounded p-4 mb-4">
                    <legend className="px-2 font-bold text-gray-700">Personal Information</legend>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="cine">
                            Cine :
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="cine"
                            type="text"
                            name="cine"
                            readOnly
                            value={cnie}
                        />
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
                            Nom - Prenom ou raison sociale :
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            name="name"
                            readOnly
                            value={user.nom + " " + user.prenom}
                        />
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
                            Telephone :
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            readOnly
                            value={ user.tel}
                        />
                        <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
                            Periode de payment habituelle : 
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            readOnly
                            value={ user.periode}
                        />
                    </div>
                </fieldset>

                <fieldset className="border-2 border-gray-300 rounded p-4 mb-4">
                    <legend className="px-2 font-bold text-gray-700">Lotissements</legend>
                    {
                        user.ids.map((NoLot)=>(<Lotissement NoLot={NoLot} cnie={cnie} />))
                    }
                    
                    {/* <Lotissement />
                    <Lotissement />
                    <Lotissement />
                    <Lotissement />
                    <Lotissement /> */}
                </fieldset>
            </form>
            </div>
        <Footer /> 
        </div>
    );
};

export default Fiche;