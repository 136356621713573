import React, { useEffect, useState } from "react";
import axios from "axios";

const ModifierArchive = ({ NoLot, cnie, closeForm, update }) => {
    // alert(NoLot);
    const [error, setError] = useState("");
    const [finalData, setFinalData] = useState(
        {
            cnie : "",
            nom : "",
            prenom : "",
            tele : "",
            periode : "",
            NoLot : null,
            motPasse : "",
            // NoLot:"",
            cnieOrigine :"",
            NoLotOrigine : NoLot
        }
    );

    useEffect(() => {
        fetchData(NoLot);
    }, [NoLot]);

    const fetchData = async (NoLot) => {
        try {
            const response = await axios.get(`http://localhost/residence/admin/archive.php?NoLot=${NoLot}`);
            if (response.data.status === "success") {
                setFinalData(response.data.result[0]);
                setError(null);
            } else {
                setError(response.data.msg);
            }
        } catch (error) {
            setError("An error occurred while fetching data.");
            console.error("Fetch clients error: ", error);
        }
    };

    const handleInputChange = (e) => {
        setFinalData({
            ...finalData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSave = async () => {
        finalData.cnieOrigine=cnie
        finalData.NoLotOrigine=NoLot
        axios.post("http://localhost/residence/admin/modifierArchive.php", finalData).then(
            (response)=>{
                alert(response.data)
                update()
                closeForm(false)
            }
        )
        // try {
        //     const response = await axios.post("http://localhost/mesSitesweb/react/Stage/syndicphp/Admin/modifierArchive.php", finalData);
        //     alert(response.data);
        //     // update();       
        //     closeForm(false);
        // } catch (error) {
        //     alert("An error occurred while saving the data.");
        //     console.error("Save data error: ", error);
        // }
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                {error ? (
                    <p className="text-red-500">{error}</p>
                ) : finalData ? (
                    <form>
                        <div className="flex flex-row">
                            <div className="mb-4">
                                <label htmlFor="NoLot" className="block text-gray-700 font-bold mb-2">N° Lot :</label>
                                <input type="text" id="NoLot" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.NoLot} onChange={handleInputChange} />

                                <label htmlFor="typeLot" className="block text-gray-700 font-bold mb-2">Type :</label>
                                <select id="typeLot" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.typeLot} onChange={handleInputChange}>
                                    <option value="appartement">Appartement</option>
                                    <option value="villa">Villa</option>
                                    <option value="magasin">Magasin</option>
                                </select>

                                <label htmlFor="dateAqui" className="block text-gray-700 font-bold mb-2">Date Acquisition :</label>
                                <input type="date" id="dateAqui" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.dateAqui} onChange={handleInputChange} />

                                <label htmlFor="etatLot" className="block text-gray-700 font-bold mb-2">Occupé par :</label>
                                <input type="text" id="etatLot" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.etatLot === 1 ? 'Locataire' : 'Propriétaire'} onChange={handleInputChange} />

                                <label htmlFor="prixMois" className="block text-gray-700 font-bold mb-2">Tarif/Mois :</label>
                                <input type="text" id="prixMois" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.prixMois} onChange={handleInputChange} />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="cnie" className="block text-gray-700 font-bold mb-2">CNIE :</label>
                                <input type="text" id="cnie" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.cnie} onChange={handleInputChange} />

                                <label htmlFor="nom" className="block text-gray-700 font-bold mb-2">Nom :</label>
                                <input type="text" id="nom" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.nom} onChange={handleInputChange} />

                                <label htmlFor="prenom" className="block text-gray-700 font-bold mb-2">Prénom :</label>
                                <input type="text" id="prenom" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.prenom} onChange={handleInputChange} />

                                <label htmlFor="tele" className="block text-gray-700 font-bold mb-2">Téléphone :</label>
                                <input type="text" id="tele" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.tele} onChange={handleInputChange} />

                                <label htmlFor="motPasse" className="block text-gray-700 font-bold mb-2">Mot de passe :</label>
                                <input type="text" id="motPasse" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.motPasse} onChange={handleInputChange} />

                                <label htmlFor="periode" className="block text-gray-700 font-bold mb-2">Période :</label>
                                <select id="periode" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={finalData.periode} onChange={handleInputChange}>
                                    <option value='mensuelle'>mensuelle</option>
                                    <option value='trimestrielle'>trimestrielle</option>
                                    <option value='annuelle'>annuelle</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2" onClick={handleSave}>
                                Sauvegarder
                            </button>
                            <button  type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => {closeForm(false)}}>
                                Annuler
                            </button>
                        </div>
                    </form>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default ModifierArchive;
