import React, { useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";

const EffectuerPaiement = () => {
  const [NoLot, setNoLot] = useState("");
  const [moisNonPayes, setMoisNonPayes] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [chequeNumber, setChequeNumber] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState("");
  const [cnie, setCnie] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");

  const handleNoLotChange = (e) => {
    setNoLot(e.target.value);
    setError("");
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value !== "Cheque") {
      setChequeNumber("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .get(`http://localhost/residence/admin/effectuerPaiement.php?NoLot=${NoLot}`)
      .then((response) => {
        if (response.data.error) {
          setError(response.data.error);
          setMoisNonPayes({});
        } else {
          setMoisNonPayes(response.data.moisNonPayes);
          setSelectedMonths([]);
          setTotalPrice(0);
          setCnie(response.data.cnie);
          setNom(response.data.nom);
          setPrenom(response.data.prenom);
        }
      })
      .catch((error) => {
        setError("Erreur de récupération des données: " + error.message);
      });
  };

  const handleMonthSelection = (year, month, price, isSelected) => {
    if (isSelected) {
      const newSelectedMonths = [...selectedMonths, { year, month, price }];
      setSelectedMonths(newSelectedMonths);
      setTotalPrice(totalPrice + price);
    } else {
      const filteredMonths = selectedMonths.filter(
        (item) => !(item.year === year && item.month === month)
      );
      setSelectedMonths(filteredMonths);
      setTotalPrice(totalPrice - price);
    }
  };

  const handleMarkPaid = () => {
    const datePaiement = new Date().toISOString().split('T')[0];
    if (window.confirm("Confirmer le paiement !")) {
      // console.log(paymentMethod)
      axios
        .post('http://localhost/residence/admin/payer.php', {
          NoLot,
          cnie,
          selectedMonths,
          datePaiement,
          modePaiement: paymentMethod,
          chequeNumber: paymentMethod === "Cheque" ? chequeNumber : null
        })
        .then((response) => {
          if(response.data.status==="success"){
            alert('Paiement effectué avec succès!');
            generatePDF();
            handleSubmit({ preventDefault: () => {} });
          }else{
            alert(response.data.status);
          }
        })
        .catch((error) => {
          alert("Erreur de paiement: " + error.message);
        });
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add the title
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text("Reçu de Paiement", 20, 20);

    // Add the payment receipt details
    doc.setFontSize(14);
    doc.setFont("times", "normal");
    doc.text(`N° LOT: ${NoLot}`, 20, 40);
    doc.text(`Nom: ${nom}`, 20, 50);
    doc.text(`Prénom: ${prenom}`, 20, 60);
    doc.text(`CNIE: ${cnie}`, 20, 70);
    doc.text(`Mode de Paiement: ${paymentMethod}`, 20, 80);
    if (paymentMethod === "Cheque") {
      doc.text(`N° Cheque: ${chequeNumber}`, 20, 90);
    }
    doc.text("Mois Payés:", 20, 100);

    // Add the list of paid months
    selectedMonths.forEach((month, index) => {
      doc.text(`${month.month} ${month.year}: ${month.price} MAD`, 20, 110 + (index * 10));
    });
    
    let date = new Date();
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    doc.text(`Date de Paiement: ${currentDate}`, 20, 110 + (selectedMonths.length * 10) + 10);

    doc.text(`Total: ${totalPrice} MAD`, 20, 110 + (selectedMonths.length * 10) + 20);

    // Add signature placeholders
    doc.text("Signature du Syndic", 20, 150 + (selectedMonths.length * 10) + 30);
    doc.text("Signature du Résident", 120, 150 + (selectedMonths.length * 10) + 30);

    doc.save(`Reçu_Paiement_${NoLot}.pdf`);
  };

  return (
    <div className="py-8 w-full px-2 mx-auto">
      <form onSubmit={handleSubmit} className="mb-6 p-4 bg-white shadow-md rounded-lg max-w-lg mx-auto flex items-center space-x-4">
        <label htmlFor="NoLot" className="text-gray-700 text-sm font-bold">
          N° LOT :
        </label>
        <input
          type="text"
          name="NoLot"
          id="NoLot"
          className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Entrez le N° LOT"
          onChange={handleNoLotChange}
          value={NoLot}
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Rechercher
        </button>
      </form>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative max-w-lg mx-auto mb-6">
          {error}
        </div>
      )}
      {Object.keys(moisNonPayes).length > 0 && (
        <div className="bg-white shadow-md rounded-lg p-4 max-w-3xl mx-auto">
          <div className="mb-4">
            <label htmlFor="paymentMethod" className="block text-gray-700 text-sm font-bold mb-2">
              Mode de paiement :
            </label>
            <select
              id="paymentMethod"
              className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="Virement">Virement</option>
            </select>
          </div>
          {paymentMethod === "Cheque" && (
            <div className="mb-4">
              <label htmlFor="chequeNumber" className="block text-gray-700 text-sm font-bold mb-2">
                N° Cheque :
              </label>
              <input
                type="text"
                required
                id="chequeNumber"
                className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Entrez le N° Cheque"
                value={chequeNumber}
                onChange={(e) => setChequeNumber(e.target.value)}
              />
            </div>
          )}
          {Object.entries(moisNonPayes).map(([year, months]) => (
            <div key={year} className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Année {year}</h3>
              <ul className="list-disc pl-5">
                {months.map((month) => (
                  <li key={month.idMois} className="flex justify-between items-center mb-2">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => handleMonthSelection(year, month.mois, month.prix, e.target.checked)}
                      />
                      <span>{month.mois} - {month.prix} MAD</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="mt-4">
            <h4 className="text-lg font-bold">Total à payer: {totalPrice} MAD</h4>
            <button
              className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleMarkPaid}
            >
              Payer sélectionnés
            </button>
          </div>
        </div>
      )}
      {Object.keys(moisNonPayes).length === 0 && !error && (
        <div className="text-center text-gray-500 mt-6">
          Aucun mois impayé trouvé.
        </div>
      )}
    </div>
  );
};

export default EffectuerPaiement;
