import React, { useEffect, useState } from "react";
import axios from "axios";
import ModifierArchive from "./ModifierArchive";
import Historique from "./Historique";

const Archive = () => {
    const [NoLot, setNoLot] = useState("");
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [showModifyPage, setShowModifyPage] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [cnie, setCnie] = useState("");

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (showModifyPage || showHistory) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
        }
    }, [showModifyPage, showHistory]);

    const fetchData = async (NoLot = "") => {
        try {
            const response = await axios.get(`http://localhost/residence/admin/archive.php?NoLot=${NoLot}`);
            if (response.data.status === "success") {
                setData(response.data.result);
                setError(null);
            } else {
                setError(response.data.msg);
            }
        } catch (error) {
            setError("An error occurred while fetching data.");
            console.error("Fetch clients error: ", error);
        }
    };

    const handleNoLotChange = (e) => {
        setNoLot(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchData(NoLot);
    };

    const handleDelete = async (NoLot, cnie) => {
        if (window.confirm("Confirmer la suppression ?")) {
            try {
                const response = await axios.get(`http://localhost/residence/admin/deleteFromArchive.php?NoLot=${NoLot}&cnie=${cnie}`);
                if (response.data.status === "success") {
                    alert(response.data.msg);
                    fetchData();
                } else {
                    alert(response.data.msg);
                }
            } catch (error) {
                alert("An error occurred while deleting the lot.");
                console.error("Delete lot error: ", error);
            }
        }
    };

    const handleFormData = (No, c) => {
        setCnie(c);
        setNoLot(No);
        setShowModifyPage(true);
    };

    const handleHistorique = (NoLot, cnie) => {
        setNoLot(NoLot);
        setCnie(cnie);
        setShowHistory(true);
    };

    return (
        <div className="py-8 w-full px-2 mx-auto">
            <form onSubmit={handleSubmit} className="mb-6 p-4 bg-white shadow-2xl rounded-lg max-w-lg mx-auto flex min-screen:flex-row flex-col space-y-5 min-screen:space-y-0 items-center space-x-4">
                <label htmlFor="NoLot" className="text-gray-700 text-sm font-bold">N° LOT :</label>
                <input
                    type="text"
                    name="NoLot"
                    id="NoLot"
                    className="bg-gray-200 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Entrez le N° LOT"
                    onChange={handleNoLotChange}
                    value={NoLot}
                />
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Rechercher
                </button>
            </form>
            {error && <div className="text-red-500 text-center mb-4">{error}</div>}
            <div className="overflow-auto">
                <table className="w-full divide-gray-200 bg-white shadow-2xl rounded-lg">
                    <thead className="bg-gray-200">
                        <tr>
                            {["N° LOT", "Type", "Date Acquisition", "Occupé par", "Tarif", "Cnie", "Nom", "Prenom", "Téléphone", "Mot de passe", "Période", "Modifier", "Historique", "Supprimer"].map((heading) => (
                                <th key={heading} scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {heading}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((item) => (
                            <tr key={item.cnie}>
                                <td className="px-3 py-4 whitespace-nowrap">{item.NoLot}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.typeLot}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.dateAqui}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.etatLot === '0' ? 'Locataire' : 'Propriétaire'}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.prixMois}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.cnie}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.nom}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.prenom}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.tele}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.motPasse}</td>
                                <td className="px-3 py-4 whitespace-nowrap">{item.periode}</td>
                                <td className="px-3 py-4 whitespace-nowrap">
                                    <button className="text-indigo-600 hover:text-indigo-900" onClick={() => handleFormData(item.NoLot, item.cnie)}>
                                        Modifier
                                    </button>
                                </td>
                                <td className="px-3 py-4 whitespace-nowrap">
                                    <button className="text-green-600 hover:text-green-900" onClick={() => handleHistorique(item.NoLot, item.cnie)}>
                                        Historique
                                    </button>
                                </td>
                                <td className="px-3 py-4 whitespace-nowrap">
                                    <button className="text-red-600 hover:text-red-900" onClick={() => handleDelete(item.NoLot, item.cnie)}>
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showModifyPage && <ModifierArchive NoLot={NoLot} cnie={cnie} update={fetchData} closeForm={setShowModifyPage} />}
            {showHistory && <Historique NoLot={NoLot} closeHistory={() => setShowHistory(false)} />}
        </div>
    );
};

export default Archive;
