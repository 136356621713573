import React, {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login =()=>{
    const navigate=useNavigate()

    function setCookie(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie =  `${name}=${value || ''}${expires}; path=/`;
    }
    
    const [user,setUser]=useState({
        tel : null,
        password:"",
    })

    const handleChange=(e)=>{
        setUser({...user , [e.target.name] : [e.target.value]})
    }

    const handleSubmit=(e)=>{
        e.preventDefault()
        const finalUserData={
            tel:user.tel,
            password : user.password
        }
        axios.post("http://localhost/residence/login.php",finalUserData).then(
            (response)=>{
                // alert(response.data.status)
                if(response.data.status==="success"){
                    alert(response.data.cnie)
                    setCookie("cnie",response.data.cnie,30)
                    if(response.data.admin){
                        alert("c'est un admin")
                        navigate("/HomePageAdmin")
                    }else{
                        navigate("/HomePage")
                    }
                    
                    // setShowLogin(false)
                }else{
                    alert(response.data.status)
                }
            }
        )
    }

    return (
        <>
            <div className="xmin-screen:flex hidden items-center justify-center h-screen bg-black fixed top-0 w-full z-10"></div>
            <div className="flex justify-center " >
                <div className={"bg-gradient-to-b mx-auto z-40 from-gray-800 fixed via-gray-700 to-gray-800  xmin-screen:mt-12 xmin-screen:w-[600px] xmin-screen:h-[400px] h-screen flex items-center justify-center w-full xmin-screen:rounded-lg shadow-lg ease-in-out duration-300"}>
                    
                    <div className="flex flex-col items-center gap-4">
                        <div className="text-center items-center flex xmin-screen:flex-row flex-col gap-3">
                            <p className="text-yellow-500 border border-yellow-500 w-fit h-[100%] rounded-tl-2xl rounded-br-2xl px-4 py-2">Residence</p>
                            <p className="text-white text-5xl font-luxury">Seraphine</p>
                        </div>
                        <div className="text-yellow-400 font-thin flex flex-col items-center text-2xl mb-5 " >
                            <span>Connectez Vous</span>
                        </div>
                        <form onSubmit={handleSubmit} action="" className="flex flex-col gap-6  xmin-screen:mt-0 mt-10 ">
                            <div className=" border-b border-gray-500 flex w-fit mx-auto h-fit " >
                                <p className="text-white h-full" >+212 </p>
                                <input
                                type="tel"
                                name="tel"
                                id=""
                                className="bg-transparent text-center text-white w-full h-fit focus:outline-none focus:border-yellow-500"
                                placeholder="Telephone"
                                onChange={handleChange}
                                />  
                            </div>
                            <div className=" border-b border-gray-500 flex w-fit mx-auto h-fit " >
                                <p className="text-white h-fit" >mdp :</p>
                                <input
                                type="password"
                                name="password"
                                id=""
                                className="bg-transparent text-white mx-auto text-center focus:outline-none focus:border-yellow-500"
                                placeholder="Mot de passe"
                                onChange={handleChange}
                                />
                            </div>
                            <input type="submit" className=" text-yellow-500 border py-3 focus:outline-none rounded-lg hover:bg-yellow-500 hover:text-white ease-in-out duration-300 mt-4 border-yellow-500  cursor-pointer " value={"Log In"} />
                        </form>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login